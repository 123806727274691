<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="">
        <p class="title">404 Page Not Found</p>
        <router-link to="/" class="is-primary button">Home</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.is-theme {
  background-color: #cccccc;
  color: #fff;
}

.card {
}
</style>
