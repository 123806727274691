<template>
    <div>
            <div class="content">
              <p class="title is-4">
                Используйте сильный пароль и изменяйте его регулярно.
              </p>
              <p>
                Использование сильного пароля является одним из основных мер
                безопасности для защиты вашей учетной записи электронной почты.
                Сильный пароль должен состоять из букв, цифр и специальных
                символов, а также быть достаточно длинным, чтобы затруднить его
                взлом.
              </p>
              <p>
                Изменение пароля регулярно также очень важно, поскольку это
                может помочь предотвратить несанкционированный доступ к вашей
                учетной записи, если ваш старый пароль был утечен или взломан.
                Рекомендуется изменять пароль каждые 3 месяца или чаще в
                зависимости от риска.
              </p>
              <button class="button is-primary">Далее</button>
            </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

</style>
