import Vue from "vue";
import VueRouter from "vue-router";
import KiberCourse from "./views/KiberCourse.vue";
import KiberDashboard from "./views/KiberDashboard.vue";
import NotFound from "./views/NotFound.vue";

Vue.use(VueRouter);

export const router = new VueRouter({
  base: "/",
  mode: "history",
  routes: [
    { name: "home", path: "/", component: KiberDashboard },
    { name: "course_item", path: "/course/:course_id", component: KiberCourse },
    { name: "course_test", path: "/course/:course_id/test", component: KiberCourse },
    { name: "course_result", path: "/course/:course_id/result", component: KiberCourse },
    { name: "notfound", path: "*", component: NotFound },
  ],
});
