<template>
    <section>
        <b-steps
            v-model="activeStep"
            :animated="true"
            :rounded="true"
            :has-navigation="false"
            icon-prev="chevron-left"
            icon-next="chevron-right"
            label-position="bottom"
            mobile-mode="minimalist">
            <b-step-item v-for="item in steps" :key="item" :step="item" :clickable="true">
                <h1 class="title has-text-centered">{{item}}</h1>
            </b-step-item>

            <template
                v-if="customNavigation"
                #navigation="{previous, next}">
                <b-button
                    outlined
                    type="is-danger"
                    icon-pack="fas"
                    icon-left="backward"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action">
                    Previous
                </b-button>
                <b-button
                    outlined
                    type="is-success"
                    icon-pack="fas"
                    icon-right="forward"
                    :disabled="next.disabled"
                    @click.prevent="next.action">
                    Next
                </b-button>
            </template>
        </b-steps>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                steps: [1,2,3,4,5],

                activeStep: 0,

                showSocial: false,
                isAnimated: true,
                isRounded: true,
                isStepsClickable: false,

                hasNavigation: true,
                customNavigation: false,
                isProfileSuccess: false,

                prevIcon: 'chevron-left',
                nextIcon: 'chevron-right',
                labelPosition: 'bottom',
                mobileMode: 'minimalist'
            }
        }
    }
</script>
