<template>
  <div>
    <!-- <section class="hero is-theme">
      <div class="hero-body">
        <p class="title">Кибертест</p>
        <p class="subtitle">Удобные курсы про безопасную работу в интернете</p>
      </div>
    </section> -->

    <section class="py-5">
      <h2 class="title is-4">Новые курсы</h2>
      <div class="columns is-flex-wrap-wrap">
        <div
          class="column is-one-third"
          v-for="(item, index) in courses"
          :key="index"
        >
          <course-card :name="item.name" :index="index" />
        </div>
      </div>
    </section>
    <section class="py-5">
      <h2 class="title is-4">Пройденные курсы</h2>
      <div class="box">Вы ещё не прошли ни одного курса</div>
    </section>
  </div>
</template>

<script>
import CourseCard from "../components/CourseCard.vue";
export default {
  data() {
    return {
      courses: [
        {
          name: "Безопасность вашего аккаунта",
        },
        {
          name: "Безопасный браузер",
        },
      ],
    };
  },
  components: {
    CourseCard,
  },
};
</script>

<style lang="scss" scoped>
.is-theme {
  background-color: #cccccc;
  color: #fff;
}

.card {

}

</style>
