<template>
  <div id="app">
    <div class="container">
    <b-navbar>
        <template v-slot:brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                КИБЕРТЕСТ
            </b-navbar-item>
        </template>
        <!-- <template v-slot:start>
            <b-navbar-item href="#">
                Home
            </b-navbar-item>
            <b-navbar-item href="#">
                Documentation
            </b-navbar-item>
            <b-navbar-dropdown label="Info">
                <b-navbar-item href="#">
                    About
                </b-navbar-item>
                <b-navbar-item href="#">
                    Contact
                </b-navbar-item>
            </b-navbar-dropdown>
        </template> -->

        <template v-slot:end>
            <b-navbar-item tag="div">
                <div class="buttons">
                    <a class="button is-light">
                        Выход
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>

    <router-view />
    </div>
<footer class="footer">
  <div class="content has-text-centered">
    <p>
        Кибертест. Мы помогаем компаниям сделать работу сотрудников в интернете безопаснее.
    </p>
  </div>
</footer>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
};
</script>

<style  lang="scss" scoped>
#app {
  /* margin-top: 60px; */
}
</style>
