<template>
  <div>
    <section>
      <div class="content my-5">
        <p class="title">Безопасность вашего аккаунта</p>
        <p class="subtitle">
          Курс поможет вам быть более осведомленным и защищенным в интернете.
        </p>
      </div>
    </section>
    <section class="py-5">
      <!-- <div class="buttons">
        <button class="button is-info">Теория</button>
        <button class="button is-info is-light">Тест</button>
        <button class="button is-info is-light">Результат</button>
      </div> -->
      <div class="box">
        <div class="columns">
          <div class="column is-2">
            <b-menu>
              <b-menu-list label="">
                <b-menu-item
                  label="Теория"
                  :active="action === 'course_item'"
                  v-on:click="openTheory"
                ></b-menu-item>
                <b-menu-item
                  label="Тест"
                  :active="action === 'course_test'"
                  v-on:click="openTest"
                ></b-menu-item>
                <b-menu-item
                  label="Результаты"
                  :active="action === 'course_result'"
                  v-on:click="openResult"
                ></b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
          <div class="column">
            <course-item v-if="action==='course_item'" />
            <course-test v-if="action==='course_test'" />
            <course-result v-if="action==='course_result'" />

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CourseItem from "../components/CourseItem.vue";
import CourseTest from "../components/CourseTest.vue";
import CourseResult from "../components/CourseResult.vue";

export default {
  components: {
    CourseItem,
    CourseTest,
    CourseResult,
  },
  data() {
    return {
      course_id: undefined,
      action: "course_item",
    };
  },
  methods: {
    openTheory() {
      this.action = "course_item";
      this.$router.push({
        name: this.action,
        params: {
          course_id: this.course_id,
        },
      });
    },
    openTest() {
      this.action = "course_test";
      this.$router.push({
        name: this.action,
        params: {
          course_id: this.course_id,
        },
      });
    },
    openResult() {
      this.action = "course_result";
      this.$router.push({
        name: this.action,
        params: {
          course_id: this.course_id,
        },
      });
    },
  },
  mounted() {
    this.course_id = this.$route.params.course_id;
  },
};
</script>

<style lang="scss" scoped></style>
