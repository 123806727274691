<template>
  <div class="card">
    <div class="card-image">
      <figure class="image is-4by3">
        <img
          :src="photoUrl"
          alt="Placeholder image"
        />
      </figure>
    </div>
    <div class="card-content">
      <div class="content">
        <p class="title is-4">{{name}}</p>
        <p>Среднее время прохождения курса <b-tag type="is-info">8 минут</b-tag></p>

      </div>
    </div>
    <div class="card">
        <footer class="card-footer">
            <router-link :to="'/course/'+index" class="card-footer-item">Пройти курс</router-link>
        </footer>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ""
        },
        index: {
            type: Number,
            default: 0
        }
    },
    computed: {
        photoUrl() {
            return `https://picsum.photos/400/300?random=${this.index}`
        }
    },
};
</script>

<style lang="scss" scoped></style>
